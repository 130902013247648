<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Name: "Nome",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Name: "Nombre",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      table: {
        heade: ["Name", "E-mail", "Cellphone"],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  mounted() {
    api
      .get("network/clients")
      .then((response) => {
        if (response.data.status == "success") {
          this.table.body = response.data.list;
          this.statData = [
            {
              icon: "bx bx-user",
              title: "Total",
              value: response.data.total,
            },
          ];
        }
      })
      .catch((error) => {
        this.table.errored = error;
      })
      .finally(() => {
        this.table.loading = false;
        if (this.table.body == "" || this.table.body == null) {
          this.table.empty = true;
        }
      });
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between">
      <h4 class="mb-0 font-size-18">{{ t("Clientes") }}</h4>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">
          {{
            t(
              "We apologize, we are unable to retrieve information at this time. Please try again later."
            )
          }}
        </div>
        <div v-else-if="table.empty">{{ t("No records found.") }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td v-for="(data, row) in td" :key="row">
                  {{ data }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>